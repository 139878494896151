var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-basic")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-border")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-fill")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-justified")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-alignment")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-dropdown")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-vertical")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-vertical-border")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-square-border")], 1),
      _c("b-col", { attrs: { md: "6" } }, [_c("nav-divider")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }