var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Vertical nav" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVertical) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("By default ")]),
        _c("code", [_vm._v("<b-nav>")]),
        _c("span", [
          _vm._v(
            " appear on a horizontal line. Stack your navigation by setting the "
          )
        ]),
        _c("code", [_vm._v("vertical")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "b-nav",
        { attrs: { vertical: "" } },
        [
          _c("b-nav-item", { attrs: { active: "" } }, [_vm._v(" Active ")]),
          _c("b-nav-item", [_vm._v("Link")]),
          _c("b-nav-item", [_vm._v("Another Link")]),
          _c("b-nav-item", { attrs: { disabled: "" } }, [_vm._v(" Disabled ")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }