var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Fill Nav" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeFill) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("To proportionately fill all available space with your ")
        ]),
        _c("code", [_vm._v("<b-nav-item>")]),
        _c("span", [_vm._v(" components, set the ")]),
        _c("code", [_vm._v("fill")]),
        _c("span", [_vm._v(" prop.")]),
        _c("span", [
          _vm._v(
            "Notice that all horizontal space is occupied, but not every nav item has the same width."
          )
        ])
      ]),
      _c(
        "b-nav",
        { attrs: { fill: "" } },
        [
          _c("b-nav-item", { attrs: { active: "" } }, [_vm._v(" Active ")]),
          _c("b-nav-item", [_vm._v("Link")]),
          _c("b-nav-item", [_vm._v("Link with a long name ")]),
          _c("b-nav-item", { attrs: { disabled: "" } }, [_vm._v(" Disabled ")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }